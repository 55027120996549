import * as React from "react";
import "../styles/index.scss";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const IndexPage = () => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Nitesh Pandey - Full-stack Software Engineer</title>
      <link rel="canonical" href="http://mysite.com/example" />
    </Helmet>
    <div className="container">
      <header>
        <h1>nitesh pandey</h1>
        <nav>
          <ul>
            <li>
              <a href="" className="active">
                Home
              </a>
            </li>
            <li>
              <a href="">Blog</a>
            </li>
            <li>
              <a href="">Projects</a>
            </li>
            <li>
              <a href="">Contact</a>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <section className="panel-title">
          <h3>Full-stack</h3>
          <h2>Software</h2>
          <h1>Engineer</h1>
        </section>
        <section className="panel-illustration">
          <div className="panel-illustration__wrapper">
            <img
              src="illustration.png"
              alt="Mt Machchapuchre in the background"
              className="illustration"
            />
          </div>
        </section>
      </main>
      <section className="skills">
        <div className="skills__title">Skills</div>
        <div className="skills__content">
          javascript, typescript, node.js, react.js, html5, angular.js, webpack,
          css, sass, less, node.js, mocha, jasmine, jest, cypress, jenkins,
          kubernetes, docker, angular, java, php, python
        </div>
      </section>
    </div>
    <footer>
      <section className="socials">
        <a href="https://www.linkedin.com/in/nites/">
          <i className="logo logo__linkedin"></i>
        </a>

        <a href="https://twitter.com/saiyeek">
          <i className="logo logo__twitter"></i>
        </a>
        <a href="https://www.instagram.com/_nitesh__/">
          <i className="logo logo__instagram"></i>
        </a>
      </section>
    </footer>
  </div>
);

export default IndexPage;
